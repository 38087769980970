<template>
  <div>
    <div style="position: absolute;display: flex;justify-content: right;">
      <el-progress
        :percentage="percentage"
        :show-text="false"
        color="#599ef8"
        type="circle"
        :width="40"
        style="padding: 20px;"
      ></el-progress>
    </div>
    <div id="page">
      <span class="front title">感谢填写，稍后将有工作人员联系您！ </span>
      <span class="pattern">THANKS!</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goIndex() {
      this.$router.push('/');
    },
    count() {
      if (this.countdown !== 0) {
        this.countdown -= 1;
        setTimeout(() => {
          console.log('+');
          this.percentage += 100 / 5;
          this.count();
        }, 1000);
      } else {
        this.goIndex();
      }
    },
  },
  data() {
    return {
      countdown: 5,
      percentage: 0,
    };
  },
  mounted() {
    this.count();
  },
};
</script>

<style scoped>
#page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#8a75d1, #a7c6ed);
}

.pattern {
  position: absolute;
  color: white;
  font-size: 20rem;
  opacity: 0.3;
}

.title {
  position: absolute;
  color: white;
  font-size: 5rem;
}

.front {
  z-index: 999;
}
</style>
